import React from 'react';

const Test = () => {

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch('/api/test', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: "",
    });

    if (response.ok) {
      const jsonData = await response.json();  // Parse the JSON response body
      console.log('Response JSON:', jsonData);
    } else {
      console.error('Error:', response.status, response.statusText);
    }
    
  }


  return ( 
    <div>
      <h2>Testing</h2>
      <img src="/abstract_wave.jpg" alt="Logo" className="header-image" />
      <form onSubmit={handleSubmit}>
        <input type="submit" />
      </form>

    </div>
  );
 };

export default Test;
