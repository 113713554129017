import React from 'react';
import Form1 from './../Components/Form1.jsx';

const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;

const EducationConsulting = () => {
  return ( 
    <div>
      <div className="pic-text-2">
        <img src="/figma-imgs/group-coaching.png" className="pic-text-2-pic" />
        <div className="pic-text-2-text">
          <h2>Education Consulting</h2>
          <p>Unlock your creative potential with personalized coaching designed to meet your unique needs and goals. Our sessions provide tailored support to help you overcome challenges, develop your skills, and achieve your aspirations. </p>
          <p>Whether you're seeking personal growth, stronger relationships, or professional development, our coaching services are designed to meet your unique needs. We offer a range of coaching options to suit different goals and challenges</p>
        </div>
      </div>

      <h3 className="centered" >Contact me today for a free consultation!</h3>

      <Form1 RECAPTCHA_V2_PUBLIC={RECAPTCHA_V2_PUBLIC} />

    </div>
  );
 };

export default EducationConsulting;
