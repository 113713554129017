import './Form.css';
import { useState, useRef } from 'react';
import Modal from './Modal.jsx'; // Import the Modal component

//import ReactDOM from 'react-dom/client';
import ReCAPTCHA from 'react-google-recaptcha'

function Form1( {RECAPTCHA_V2_PUBLIC} ) {
  //const [inputs, setInputs] = useState("");

  const [inputs, setInputs] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    contact_method: [],
    contact_time: '',
    inquiring_about: [],
    message: ''
  });


  const [errors, setErrors] = useState({});

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const recaptchaRef = useRef(null); // Reference to the reCAPTCHA component


  const validateForm = () => {
    const newErrors = {};
    const phoneRegex = /^\d{10}$/; // 10 digits
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    if (!inputs.firstname) newErrors.firstname = '* First Name is required';
    if (!inputs.lastname) newErrors.lastname = '* Last Name is required';

    if (!inputs.phone && !inputs.email) {
      newErrors.phone = "* Either phone or email is required";
      newErrors.email = "* Either phone or email is required";
    }
    else {
    
      if (inputs.phone && !phoneRegex.test(inputs.phone) ) {
        newErrors.phone = "* Phone should be 10 digits, no other characters";
        
      }
      if (inputs.email && !emailRegex.test(inputs.email) ) {
        newErrors.email = "* Please use a valid email address";

      }
    }

    if (inputs.contact_method.length === 0) {
      newErrors.contact_method = "* Please select atleast one contact method"
    }

    if (inputs.inquiring_about.length === 0) {
      newErrors.inquiring_about = "* Please select atleast one inquiry reason"
    }

    //if (!inputs.age) newErrors.age = 'Age is required';
    //if (!inputs.dob) newErrors.dob = 'Date of Birth is required';
    if (!inputs.message) newErrors.message = '* Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /*
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setErrors((errors) => ({ ...errors, [name]: '' })); 
  }
  */

  const handleChange = (event) => {
      const { name, value, type, checked } = event.target;
      setInputs((values) => {
        if (type === 'checkbox' && name === 'contact_method') {
          // Handle checkbox updates for 'contact_method'
          const checks = values.contact_method || [];

          return {
            ...values,
            contact_method: checked
              ? [...checks, value] // Add checked value
              : checks.filter((pref) => pref !== value), // Remove unchecked value
          };
        } 
        else if (type === 'checkbox' && name === 'inquiring_about') {
          const checks = values.inquiring_about || [];
          return {
            ...values,
            inquiring_about: checked
              ? [...checks, value] // Add checked value
              : checks.filter((pref) => pref !== value), // Remove unchecked value
          };

        }
        /*
        else if (type === 'checkbox' && name === 'contact_time') {
          const checks = values.contact_time || [];

          return {
            ...values,
            contact_time: checked
              ? [...checks, value] // Add checked value
              : checks.filter((pref) => pref !== value), // Remove unchecked value
          };

        }*/ 
        else {
          // Handle text inputs (email, phone, etc.)
          return { ...values, [name]: value };
        }
      });

      // Remove error from the updated field
      setErrors(prev => ({ ...prev, [name]: '' }));

      // Add logic to clear error from 'phone' if 'email' is updated and vice versa
      if (name === 'phone') {
        setErrors(prev => ({ ...prev, email: '' }));
      } else if (name === 'email') {
        setErrors(prev => ({ ...prev, phone: '' }));
      }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError(false); 

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      setModalTitle("Form Error");
      setModalMessage('Please fill out all required fields.');
      return; 
    }

    if (!recaptchaValue) {
      setRecaptchaError(true);
      setModalMessage('Please complete the reCaptcha.');
      return;
    }

    setModalTitle("");
    setModalMessage("Your Contact Iinformation is being sent...");

    try {
      const response = await fetch('/api/contact-me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...inputs, recaptchaValue }),
      });

      const result = await response.json();
      //alert('Form submission result:' + result.message);
      setModalTitle("Server Response");
      setModalMessage(result.message);

      // reset form
      setInputs({
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          contact_method: [],
          contact_time: '',
          inquiring_about: [],
          message: ''
        });

      //reset var that tracks recaptcha state
      setRecaptchaValue(null);
      //reset recaptcha 
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      return;
    } catch (error) {
      //alert('Error during form submission:' + error);
      setModalTitle("Server Error");
      setModalMessage(error.message);
      return;

    }
    
  }

  const closeModal = () => {
    setModalMessage('');
    setModalTitle('');
  };

  return (
  <div>

    <form onSubmit={handleSubmit}>
      <h3>Contact Me</h3>

      <label>
        {errors.firstname && <p className="form-error-text">{errors.firstname}</p>}
        <input 
          type="text" 
          name="firstname" 
          placeholder="First Name"
          value={inputs.firstname || ""} 
          onChange={handleChange}
        />
      </label>

      <label>
        {errors.lastname && <p className="form-error-text">{errors.lastname}</p>}
        <input 
          type="text" 
          name="lastname" 
          placeholder="Last Name"
          value={inputs.lastname || ""} 
          onChange={handleChange}
        />
      </label>

      <label>
        {errors.email && <p className="form-error-text">{errors.email}</p>}
        <input 
          type="text" 
          name="email"
          placeholder="Email"
          value={inputs.email || ""} 
          onChange={handleChange}
        />
      </label>

      <label>
        {errors.phone && <p className="form-error-text">{errors.phone}</p>}
        <input 
          type="tel" 
          name="phone" 
          placeholder="Phone"
          value={inputs.phone || ""} 
          onChange={handleChange}
        />
      </label>


      <label>
        <h5 className="form-label">Contact Preference</h5>
        <p className="form-sub-instruct">select all that apply</p>
        {errors.contact_method && <p className="form-error-text">{errors.contact_method}</p>}
        <div className="checkbox-container">
          <input 
            type="checkbox" 
            name="contact_method" 
            value="email" 
            checked={inputs.contact_method?.includes("email") || false} 
            onChange={handleChange}
          />
          <label><p>Email</p></label>
        </div>
        <div className="checkbox-container">
          <input 
            type="checkbox" 
            name="contact_method" 
            value="phone" 
            checked={inputs.contact_method?.includes("phone") || false} 
            onChange={handleChange}
          />
          <label><p>Phone</p></label>
        </div>
        <div className="checkbox-container">
          <input 
            type="checkbox" 
            name="contact_method" 
            value="text" 
            checked={inputs.contact_method?.includes("text") || false} 
            onChange={handleChange}
          />
          <label><p>Text</p></label>
        </div>
      </label>

      { inputs.contact_method.includes("phone") && (
        <label>
        {/*<h5 className="form-label">Preferred Contact Time:</h5>*/}
          {errors.contact_time && <p className="form-error-text">{errors.contact_time}</p>}
          <input 
            type="text" 
            name="contact_time"
            placeholder="Best Time to Contact (e.g. mornings, weekdays 2-3pm, any)"
            value={inputs.contact_time || ""} 
            onChange={handleChange}
          />

        </label> 
      )}
        {/*
        <div className="checkbox-container">
          <input 
            type="checkbox" 
            name="contact_time" 
            value="morning" 
            checked={inputs.contact_time?.includes("morning") || false} 
            onChange={handleChange}
          />
          <label><p>Morning</p></label>
        </div>
        <div className="checkbox-container">
          <input 
            type="checkbox" 
            name="contact_time" 
            value="afternoon" 
            checked={inputs.contact_time?.includes("afternoon") || false} 
            onChange={handleChange}
          />
          <label><p>Afternoon</p></label>
        </div>
        <div className="checkbox-container">
          <input 
            type="checkbox" 
            name="contact_time" 
            value="night" 
            checked={inputs.contact_time?.includes("night") || false} 
            onChange={handleChange}
          />
          <label><p>Night</p></label>
        </div>
        */}


        <label>
          <h5 className="form-label">Inquiring About</h5>
          <p className="form-sub-instruct">select all that apply</p>
          {errors.inquiring_about && <p className="form-error-text">{errors.inquiring_about}</p>}
          <div className="checkbox-container">
            <input 
              type="checkbox" 
              name="inquiring_about" 
              value="individual_coaching" 
              checked={inputs.inquiring_about?.includes("individual_coaching") || false} 
              onChange={handleChange}
            />
            <label><p>Individual Coaching</p></label>
          </div>
          <div className="checkbox-container">
            <input 
              type="checkbox" 
              name="inquiring_about" 
              value="group_coaching" 
              checked={inputs.inquiring_about?.includes("group_coaching") || false} 
              onChange={handleChange}
            />
            <label><p>Group Coaching</p></label>
          </div>
          <div className="checkbox-container">
            <input 
              type="checkbox" 
              name="inquiring_about" 
              value="couples_coaching" 
              checked={inputs.inquiring_about?.includes("couples_coaching") || false} 
              onChange={handleChange}
            />
            <label><p>Couples Coaching</p></label>
          </div>
          <div className="checkbox-container">
            <input 
              type="checkbox" 
              name="inquiring_about" 
              value="education_consultation" 
              checked={inputs.inquiring_about?.includes("education_consultation") || false} 
              onChange={handleChange}
            />
            <label><p>Education Consultation</p></label>
          </div>
          <div className="checkbox-container">
            <input 
              type="checkbox" 
              name="inquiring_about" 
              value="workshops" 
              checked={inputs.inquiring_about?.includes("workshops") || false} 
              onChange={handleChange}
            />
            <label><p>Workshops</p></label>
          </div>
        </label>


      {/*<label>Enter your age:
        {errors.age && <p className="form-error-text">{errors.age}</p>}
        <input 
          type="number" 
          name="age" 
          value={inputs.age || ""} 
          onChange={handleChange}
        />
      </label>

      <label>Date of Birth:
        {errors.dob && <p className="form-error-text">{errors.dob}</p>}
        <input 
          type="date" 
          name="dob" 
          value={inputs.dob|| ""} 
          onChange={handleChange}
        />
      </label>*/}

      <label>
        {errors.message && <p className="form-error-text">{errors.message}</p>}
        <textarea
          name="message"
          placeholder="Your Message Here..."
          value={inputs.message || ""}
          onChange={handleChange}
          rows="4" // Sets the number of visiblsle rows
          cols="50" // Sets the number of visible columns
        />
      </label>

      <div className="recaptcha-wrap" >
        {recaptchaError && <p className="form-error-text">* reCAPTCHA is required</p>}

        <ReCAPTCHA
          sitekey={RECAPTCHA_V2_PUBLIC}
          onChange={handleRecaptchaChange}
          ref={recaptchaRef} 
        />
      </div>

      <input type="submit" value="Submit" />

    </form>

    <Modal message={modalMessage} title={modalTitle} onClose={closeModal} />

    </div>


  )
}

export default Form1;
