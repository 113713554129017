import React from 'react';
import { Link } from 'react-router-dom';

const Workshops = () => {
return (
    <div>


      <div className="header-img-wrap">
        <img src="/figma-imgs/purple-mountains.png" alt="Logo" className="header-image" />
        <h3 className="header-text">Workshops</h3>
      </div>

      {/*<h3>There are no upcoming workshops</h3>*/}
      <div className="workshops-list">

      	<div className="workshops-row">
	        <img src="/figma-imgs/rocks.png" alt="" className="workshops-img" />
	        <Link to="/workshop-example" className="workshops-card">
	        	<h3 className="workshops-title">Workshop Heading</h3>
	        	<p className ="workshops-date">Jan 1 2017</p>
	        	<p className ="workshops-location">12 Main St Buffalo NY</p>
        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
	        	<p className="workshops-cost" >$60</p>

	        </Link>
        </div>
        <div className="workshops-row">
        	<img src="/figma-imgs/path.png" alt="" className="workshops-img" />
        	<Link to="/workshop-example" className="workshops-card">
	        	<h3 className="workshops-title">Workshop Heading</h3>
	        	<p className ="workshops-date">Jan 1 2017</p>
	        	<p className ="workshops-location">12 Main St Buffalo NY</p>
        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
		   		<p className="workshops-cost" >$60</p>
	        </Link>
        </div>

      	<div className="workshops-row">
	        <img src="/figma-imgs/sunset.png" alt="" className="workshops-img" />
	        <Link to="/workshop-example" className="workshops-card">
	        	<h3 className="workshops-title">Workshop Heading</h3>
	        	<p className ="workshops-date">Jan 1 2017</p>
	        	<p className ="workshops-location">12 Main St Buffalo NY</p>
        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
	    		<p className="workshops-cost" >$60</p>
	    	</Link>
		</div>

      	<div className="workshops-row">
	        <img src="/figma-imgs/hands.png" alt="" className="workshops-img" />
	        <Link to="/workshop-example" className="workshops-card">
	        	<h3 className="workshops-title">Workshop Heading</h3>
	        	<p className ="workshops-date">Jan 1 2017</p>
	        	<p className ="workshops-location">12 Main St Buffalo NY</p>
        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
		   		<p className="workshops-cost" >$60</p>
		    </Link>
		</div>

	  </div> {/* End Workshops List*/}

    </div>
  );
};

export default Workshops;
