import React from 'react';
import Form1 from './../Components/Form1.jsx';

const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;



const CreativesCoaching = () => {
  return ( 
   <div>
      <div className="pic-text-2">
        <img src="/figma-imgs/dancers.png" className="pic-text-2-pic" />
        <div className="pic-text-2-text">
          <h2>Coaching for Creatives</h2>
          <p>As a creative, you know the power of imagination and innovation—but balancing inspiration with structure can be challenging. Our coaching for creatives is designed to help you break through creative blocks, refine your artistic vision, and turn your passions into a sustainable career.</p>         
          <p>Let us help you nurture your creativity, refine your process, and reach your fullest potential as an artist. Your creative breakthrough starts here!</p>
        </div>
      </div>

      <h3 className="centered" >Contact me today for a free consultation!</h3>

      <Form1 RECAPTCHA_V2_PUBLIC={RECAPTCHA_V2_PUBLIC} />

    </div>
  );
 };

export default CreativesCoaching;