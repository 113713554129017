import React, { useState } from 'react';

const Admin = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Retrieve the JWT token from local storage
    const token = localStorage.getItem('jwtToken');

    if (!token) {
      setResponseMessage('No JWT token found.');
      return;
    }

    try {
      // Send the JWT token to the server
      const response = await fetch('/api/ping-logged-in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Send the token in the Authorization header
        },
        body: JSON.stringify({}) // You can send additional data if needed
      });

      const result = await response.json();

      if (response.ok) {
        setResponseMessage('Successfully sent JWT token.');
      } else {
        setResponseMessage(`Error: ${result.message || 'Unknown error'}`);
        window.location.href = '/login'; // or use useNavigate hook

      }

    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    }
  };

  return ( 
    <div>
      <h2>Admin Page</h2>
      <p>This is the admin Page. </p>
      <div>
        <form onSubmit={handleSubmit}>
          <button type="submit">Ping Server</button>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </div>
  );
 };

export default Admin;
