//import MyForm from './../Components/Form.js';

import React, { useState } from 'react';
import Form1 from '../Components/Form1.jsx';
import Form2 from '../Components/Form2.jsx';
import Form3 from '../Components/Form3.jsx';

const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;

const Contact = () => {

  return ( 
    <div>
      <div className="header-img-wrap">
        <img src="/figma-imgs/header-mountains.png" alt="Logo" className="header-image" />
        <h3 className="header-text">Contact Me</h3>
      </div>
      <p>Whether you're seeking therapy for the first time or continuing your path, I’m committed to providing a safe, compassionate space where you can explore your thoughts and feelings.</p>

      <p>If you have any questions or would like to schedule an appointment, please don’t hesitate to reach out. I look forward to connecting with you.</p>
      <div className="form-wrap">
  			<div className="form-content-wrap">
          <Form1 RECAPTCHA_V2_PUBLIC={RECAPTCHA_V2_PUBLIC} />
  			</div>
      </div>
    </div>
  );
 };
 



export default Contact;
