import React from 'react';

const Home = () => {
  return ( 
    <div className="home-page">
      <div className="pic-text">
        <img src="/figma-imgs/lace-pic.png" alt="lace-pic" className="lace-pic"/> 
        <div className="pic-text-text">
          <h2><i>Tagline Tagline Tagline</i></h2>
          <p>Let’s work together to find solutions and build tools to support you in your goals.</p>
          <p>Contact me for a free consultation to discuss how coaching can help you move forward!</p>
        </div>
      </div>
      <div className="three-buttons">
        <button >
          <img src="/figma-imgs/Jump.svg" className="" />
          <h3>Individual, Group, and</h3><h3>Couples Coaching</h3>
        </button>
        <button>
          <img src="/figma-imgs/Paint-Palette.svg" className="" />
          <h3>Coaching for</h3><h3>Creatives</h3>
        </button>
        <button>
          <img src="/figma-imgs/Natural-Food.svg" className="" />
          <h3>Wellness</h3><h3>Workshops</h3>
        </button>
      </div>

      <div className="about-me">
        <h2 className="h2-title">About Me</h2>
        <p>I have been dedicated to helping youth thrive for over 25 years. For most of that time, I have been an educator and stage director, using theatre arts as a vehicle to help people find their creative voice. I then decided to dedicate the rest of my career to becoming a counselor in order to be able to focus my support on the mental health and well-being of others, especially youth. </p>
        <p>I received my master’s in counseling from William James College and experiential training at McClains Hospital as well as The Home for Little Wonderers.</p>
      </div>
      
      <div className="text-pic">
        <div className="our-process">
          <h2 className="h2-title">Our Process</h2>
          <p>Our time together will involve getting to know your perspective on these difficulties or predicaments in your life, developing an understanding of the nature of the difficulties, and generating better ways to cope with or manage those difficulties. </p>
          <p>Sometimes, the predicament will disappear altogether, but other times, learning to manage or cope with difficulties is a good outcome.
          </p>
        </div>
        <img src="/figma-imgs/lavender-plant.png" className="" />
      </div>
    </div>
  );
 };

export default Home;
