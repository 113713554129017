import React from 'react';
import './Payments.css'; // Optional: for custom styling
import '../Components/Form.css'

import PaypalButtons from './../Components/PaypalButtons.jsx';



//const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;


const Payments = () => {

  return ( 
    <div>
      <h2>Payments</h2>

      <h4 className="centered"> To pay for coaching sessions use the venmo or paypal interfaces below...</h4>
       
      <PaypalButtons />

      {/* <div className="venmo-wrap">
      	<h2>Venmo</h2>
      	<img className="qr-image" src="../../lace-venmo-qr.png" alt="Venmo Qr Code" />
      	<p>@Lace-Campbell</p>
      </div>
      <hr/>
      */}
      <div className="paypal-wrap"> 
        <h2>Or Scan the QR Code</h2>
        <img className="qr-image" src="../../bt4w-paypal-qr.png" alt="Venmo Qr Code" />
        
        {/*
        <div><a target="_blank" rel="noopener noreferrer" href="https://www.paypal.com/ncp/payment/5SUCRG384XHV8" >PayPal</a></div>
      	<form>

      		<input 
      			type="text" 
          		name="firstname" 
          		placeholder="First Name"
          	/>

          	<input 
      			type="text" 
          		name="lastname" 
          		placeholder="Last Name"
          	/>
          	<input 
      			type="text" 
          		name="description" 
          		placeholder="Service Description"
          	/>
          	<input type="submit" />
      	</form>
        */}

      </div>
     
    </div>
  );
 };

export default Payments;
